import { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import Cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import useCookieBannerState from './useCookieBannerState';
import { COOKIE_POLICY_STATUS, COOKIE_POLICY_STATUS_DURATION, COOKIE_POLICY_ACCEPTED, COOKIE_POLICY_REJECTED } from '@mdc/constants';
import { necessaryCookies, analyticsCookies, advertisementCookies, uncategorizedCookies, functionalCookies } from './CookieData';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import PropTypes from 'prop-types';

import './CookieBanner.scss';


const COOKIE_POLICY_STATUS_LOCAL = 'opswat-cookie-policy';

const CookieBanner = () => {
    const { t, ready } = useTranslation();
    const [isClient, setIsClient] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const isCurrentEnvLocal = process.env.ENVIRONMENT === 'local';
    const cookieName = isCurrentEnvLocal ? COOKIE_POLICY_STATUS_LOCAL : COOKIE_POLICY_STATUS;
    const cookieOptions = {
        secure: isCurrentEnvLocal ? false : true,
        sameSite: 'Strict'
    };

    const [isRejected, setIsRejected] = useState(false);
    const [isAccepted, setIsAccepted] = useState(() => Cookie.get(cookieName) || false);
    const [isVisible, setIsVisible] = useCookieBannerState();
    const [showNecessary, setShowNecessary] = useState(false);
    const [showFunctional, setShowFunctional] = useState(false);
    const [showAnalytics, setShowAnalytics] = useState(false);
    const [showAdvertisement, setShowAdvertisement] = useState(false);
    const [showUncategorized, setShowUncategorized] = useState(false);
    const [analyticsEnabled, setAnalyticsEnabled] = useState(false);
    const [advertisementsEnabled, setAdvertisementsEnabled] = useState(false);
    const [uncategorizedEnabled, setUncategorizedEnabled] = useState(false);
    const [showFullText, setShowFullText] = useState({});

    const onAccept = () => {
        const acceptOptions = {
            ...cookieOptions,
            expires: COOKIE_POLICY_STATUS_DURATION
        };
        Cookie.set(cookieName, COOKIE_POLICY_ACCEPTED, acceptOptions);
        setIsAccepted(true);
        setIsVisible(false);
    };

    const onReject = () => {
        const rejectOptions = {
            ...cookieOptions,
            expires: new Date(new Date().getTime() + 30 * 60 * 1000)
        };
        Cookie.set(cookieName, COOKIE_POLICY_REJECTED, rejectOptions);
        setIsRejected(true);
        setIsVisible(false);
    };

    const onCookieSettings = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const toggleNecessary = () => {
        setShowNecessary((prev) => !prev);
    };

    const toggleUncategorized = () => {
        setShowUncategorized((prev) => !prev);
    };

    const toggleFunctional = () => {
        setShowFunctional((prev) => !prev);
    };

    const toggleAnalytics = () => {
        setShowAnalytics((prev) => !prev);
    };

    const toggleAdvertisement = () => {
        setShowAdvertisement((prev) => !prev);
    };

    const toggleAnalyticsEnabled = () => {
        setAnalyticsEnabled((prev) => !prev);
    };

    const toggleAdvertismentsEnabled = () => {
        setAdvertisementsEnabled((prev) => !prev);
    };

    const toggleUncategorizedEnabled = () => {
        setUncategorizedEnabled((prev) => !prev);
    };

    const toggleShowFullText = (index) => {
        setShowFullText((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleRejectAndClose = () => {
        onReject();
        closeModal();
    };

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isVisible && ready && isClient && !isRejected && !isAccepted) {
        setIsVisible(true);
    }

    if (!isVisible) {
        return null;
    }

    const CookieRow = ({ title, value }) => (
        <div className='cookie-row'>
            <span className='cookie-cell title'>{t(title)}</span>
            <span className='cookie-cell'>{t(value)}</span>
        </div>
    );

    return (
        <div className='cookieWrapper'>
            <Container className='cookieBanner'>
                <Row className='d-flex justify-content-center align-items-center'>
                    <Col className='text d-flex flex-column align-items-center' md={{ span: 7, order: 1 }} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
                        <span className="privacyNotice">
                            {t('We value your privacy')}
                        </span>
                        <span className='align-self-center'>
                            {t('We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept", you consent to our use of cookies.')}{' '}
                            <OutboundLink href={'https://www.opswat.com/legal/cookies-policy'} target="_blank" rel="noopener noreferrer" className='outbound-link'>
                                {t('Please see our Cookie Policy')}
                            </OutboundLink>
                        </span>
                    </Col>
                    <Col className='buttonWrapper' md={{ span: 5, order: 2 }} xs={{ span: 12, order: 2 }}>
                        <Button className='button reject' onClick={onReject} variant='outline-primary'>
                            {t('Reject All')}
                        </Button>
                        <Button className='button reject' onClick={onCookieSettings} variant='outline-primary'>
                            {t('Cookies Settings')}
                        </Button>
                        <Button className='button accept' onClick={onAccept} variant='outline-primary'>
                            {t('Accept All')}
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={closeModal} dialogClassName='modal-custom' backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Customize Consent Preferences')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='cookie-text'>
                        <p>{t('We use cookies to help you navigate efficiently and perform certain functions.')}</p>

                        <p>
                            {t('The cookies that are categorized as Necessary are stored on your browser as they are essential for enabling the basic functionalities of the site.')}{' '}
                            {!showFullText['cookie'] && (
                                <>
                                    ...
                                    <Button onClick={() => toggleShowFullText('cookie')} variant='link' className='buttonLink'>
                                        {t('Show more')}
                                    </Button>
                                </>
                            )}
                        </p>

                        {showFullText['cookie'] && (
                            <>
                                <p>{t('We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent.')}</p>

                                <p>
                                    {t('You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.')}{' '}
                                    <Button onClick={() => toggleShowFullText('cookie')} variant='link' className='buttonLink'>
                                        {t('Show less')}
                                    </Button>
                                </p>
                            </>
                        )}
                    </div>
                    <div className={`cookie-category ${showNecessary ? 'expanded' : ''}`}>
                        <button className='cookie-option' onClick={toggleNecessary} type='button'>
                            <span className='cookie-icon'></span>
                            <span className='cookie-label'>{t('Necessary')}</span>
                            <span className='badgeCookie'>Always Active</span>
                        </button>
                        <p className='cookie-description'>
                            {t(
                                'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.'
                            )}
                        </p>
                        {showNecessary && (
                            <div className='cookie-details'>
                                <div className='cookie-table'>
                                    {necessaryCookies.map((cookie, index) => (
                                        <div key={cookie.name}>
                                            {index > 0 && <div className='cookie-row border-top'></div>}
                                            <CookieRow title='Cookie' value={cookie.name} />
                                            <CookieRow title='Duration' value={cookie.duration} />
                                            <CookieRow title='Description' value={cookie.description} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={`cookie-category ${showFunctional ? 'expanded' : ''}`}>
                        <button className='cookie-option' onClick={toggleFunctional} type='button'>
                            <span className='cookie-icon'></span>
                            <span className='cookie-label'>{t('Functional')}</span>
                            <Form.Check type='switch' id='necessary-switch' className='form-check' checked disabled />
                        </button>
                        <p className='cookie-description'>
                            {t('Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.')}
                        </p>
                        {showFunctional && (
                            <div className='cookie-details'>
                                <div className='cookie-table'>
                                    {functionalCookies.map((cookie, index) => (
                                        <div key={cookie.name}>
                                            {index > 0 && <div className='cookie-row border-top'></div>}
                                            <CookieRow title='Cookie' value={cookie.name} />
                                            <CookieRow title='Duration' value={cookie.duration} />
                                            <CookieRow title='Description' value={cookie.description} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={`cookie-category ${showAnalytics ? 'expanded' : ''}`}>
                        <div className='cookie-option'>
                            <button className='cookie-option' onClick={toggleAnalytics} type='button'>
                                <span className='cookie-icon'></span>
                                <span className='cookie-label'>{t('Analytics')}</span>
                            </button>
                            <Form.Check
                                type='switch'
                                id='analytics-switch'
                                className='form-check'
                                label={<span className='sr-only'>{t('Analytics')}</span>}
                                checked={analyticsEnabled}
                                onChange={toggleAnalyticsEnabled}
                            />
                        </div>
                        <p className='cookie-description'>
                            {t('Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.')}
                        </p>
                        {showAnalytics && (
                            <div className='cookie-details'>
                                <div className='cookie-table'>
                                    {analyticsCookies.map((cookie, index) => (
                                        <div key={cookie.name}>
                                            {index > 0 && <div className='cookie-row border-top'></div>}
                                            <CookieRow title='Cookie' value={cookie.name} />
                                            <CookieRow title='Duration' value={cookie.duration} />
                                            <CookieRow title='Description' value={cookie.description} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={`cookie-category ${showAdvertisement ? 'expanded' : ''}`}>
                        <div className='cookie-option'>
                            <button className='cookie-option' onClick={toggleAdvertisement} type='button'>
                                <span className='cookie-icon'></span>
                                <span className='cookie-label'>{t('Advertisement')}</span>
                            </button>
                            <Form.Check
                                type='switch'
                                id='advertisement-switch'
                                className='form-check'
                                label={<span className='sr-only'>{t('Advertisement')}</span>}
                                checked={advertisementsEnabled}
                                onChange={toggleAdvertismentsEnabled}
                            />
                        </div>
                        <p className='cookie-description'>
                            {t('Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.')}
                        </p>
                        {showAdvertisement && (
                            <div className='cookie-details'>
                                <div className='cookie-table'>
                                    {advertisementCookies.map((cookie, index) => (
                                        <div key={cookie.name}>
                                            {index > 0 && <div className='cookie-row border-top'></div>}
                                            <CookieRow title='Cookie' value={cookie.name} />
                                            <CookieRow title='Duration' value={cookie.duration} />
                                            <CookieRow title='Description' value={cookie.description} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={`cookie-category ${showUncategorized ? 'expanded' : ''}`}>
                        <div className='cookie-option'>
                            <button className='cookie-option' onClick={toggleUncategorized} type='button'>
                                <span className='cookie-icon'></span>
                                <span className='cookie-label'>{t('Uncategorized')}</span>
                            </button>
                            <Form.Check
                                type='switch'
                                id='uncategorized-switch'
                                className='form-check'
                                label={<span className='sr-only'>{t('Uncategorized')}</span>}
                                checked={uncategorizedEnabled}
                                onChange={toggleUncategorizedEnabled}
                            />
                        </div>
                        <p className='cookie-description'>
                            {t('Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.')}
                        </p>
                        {showUncategorized && (
                            <div className='cookie-details'>
                                <div className='cookie-table'>
                                    {uncategorizedCookies.map((cookie, index) => (
                                        <div key={cookie.name}>
                                            {index > 0 && <div className='cookie-row border-top'></div>}
                                            <CookieRow title='Cookie' value={cookie.name} />
                                            <CookieRow title='Duration' value={cookie.duration} />
                                            <CookieRow title='Description' value={cookie.description} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='buttonCookie CustomReject' variant='outline-primary' onClick={handleRejectAndClose}>
                        {t('Reject All')}
                    </Button>
                    <Button className='buttonCookie CustomReject' variant='outline-primary' onClick={onAccept}>
                        {t('Save My Preferences')}
                    </Button>
                    <Button className='buttonCookie CustomAccept' variant='outline-primary' onClick={onAccept}>
                        {t('Accept All')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

CookieBanner.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,

};

export default CookieBanner;
