export const necessaryCookies = [
    { name: '__Host-opswat-already-used', duration: 'Session', description: 'No description' },
    { name: '__Host-opswat-auth', duration: '15 days', description: 'No description' },
    { name: '__Host-opswat-auth.sig', duration: '15 days', description: 'No description' },
    { name: '__Host-opswat-cookie-policy', duration: '30 days', description: 'No description' },
    { name: '__Host-opswat-last-active', duration: '15 days', description: 'No description' },
    { name: '__Host-opswat-loginRedirect', duration: 'Session', description: 'No description' },
    { name: '__Host-opswat-loginRedirect.sig', duration: 'Session', description: 'No description' },
    { name: '__Host-sso', duration: '15 days', description: 'No description' },
    { name: '__Host-sso.sig', duration: '15 days', description: 'No description' },
    { name: '__Host-sso.sub', duration: 'Session', description: 'No description' },
    { name: '__Host-sso.sub.sig', duration: 'Session', description: 'No description' },
    { name: '__opswat-login', duration: '15 minutes', description: 'No description' },
    { name: '_stripe_mid', duration: '1 year', description: 'Stripe sets this cookie to process payments.' },
    {
        name: 'cookieyes-consent',
        duration: '1 year',
        description: 'CookieYes sets this cookie to remember users consent preferences so that their preferences are respected on their subsequent visits to this site. It does not collect or store any personal information of the site visitors.'
    },
    { name: 'loginCommercial', duration: 'Session', description: 'No description' },
    { name: ' loginCommercial.sig', duration: 'Session', description: 'No description' },
];

export const functionalCookies = [
    {
        name: 'sentry-sc',
        duration: '1 year',
        description: 'Sentry sets this cookie to helps developers monitor application crashes, JavaScript errors, and performance bottlenecks. It does not collect or store any personal information of the site visitors.'
    }
];

export const analyticsCookies = [
    {
        name: '_ga',
        duration: '1 year 1 month 4 days',
        description: 'The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site\'s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.'

    },
    {
        name: '_ga_2LGNEDYVDB',
        duration: '1 year 1 month 4 days',
        description: 'The _ga cookie, installed by Google Analytics.'
    },
    {
        name: '_ga_46YQD7ZC7R',
        duration: '1 year 1 month 4 days',
        description: 'The _ga cookie, installed by Google Analytics.'
    },
    {
        name: 'ar_debug',
        duration: '1 year 1 month 4 days',
        description: 'The ar_debug cookie, installed by Google Analytics.'
    },
    {
        name: 'MATOMO_SESSID',
        duration: '1 year 1 month 4 days',
        description: ' The MATOMO_SESSID cookie is used by Matomo Analytics for session management in certain scenarios. This cookie is mainly related to authentication and session handling.'
    }
];

export const advertisementCookies = [
    {
        name: 'MUID',
        duration: '1 year 24 days',
        description: 'Bing sets this cookie to recognize unique web browsers visiting Microsoft sites. This cookie is used for advertising, site analytics, and other operations.'
    }
];

export const uncategorizedCookies = [
    { name: 'CLID', duration: '1 year', description: 'No description' },
    { name: '_clck', duration: '1 year', description: 'No description' },
    { name: '_clsk', duration: '1 year', description: 'No description' },
    { name: '_pk_id.2.c8a1', duration: '1 year', description: 'No description' },
    { name: '_pk_id.6.5c52', duration: '1 year 1 month', description: 'No description' },
    { name: '_pk_ref.2.c8a1', duration: '1 year 1 month', description: 'No description' },
    { name: '_pk_ses.2.c8a1', duration: '30 mins', description: 'No description' },
];
