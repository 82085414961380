import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { getRandomID } from '@mdc/services';
import { SCAN_SANITIZED_STATUS } from '@mdc/constants';

import './DownloadSanitizedButton.scss';

/* eslint-disable camelcase */
const DownloadSanitizedButton = ({ sanitized, downloadSanitized }) => {
    const { t, ready } = useTranslation();

    const [buttonTooltipId] = useState(`ButtonSanitized${getRandomID(5)}`);

    const downloadSanitizedClassName = useMemo(() => classNames({
        'hidden': !sanitized || sanitized.result === SCAN_SANITIZED_STATUS.error || sanitized.file_expired || sanitized.original_data_id,
        'disabled': sanitized?.result !== SCAN_SANITIZED_STATUS.allowed || !sanitized?.file_path
    }, 'downloadSanitizedButton align-middle'), [sanitized]);

    const tooltipDOM = useMemo(() => {
        const getTooltipText = () => {
            if (sanitized?.result === SCAN_SANITIZED_STATUS.allowed && !sanitized?.file_path) {
                return t('You cannot download the sanitized version because the original file was uploaded by someone else');
            } else if (sanitized?.reason == 'Sanitization was not finished in time.') {
                return sanitized?.reason;
            } else if (sanitized?.result === SCAN_SANITIZED_STATUS.blocked && sanitized?.reason != 'Sanitization was not finished in time.') {
                return t('The sanitized file is infected');
            }
            return t('Available when sanitization finishes');
        };

        if (sanitized?.result !== SCAN_SANITIZED_STATUS.allowed || !sanitized?.file_path) {
            return <Tooltip id={buttonTooltipId}
                className='sanitizedToolTip'
                variant='dark'
                delayHide={300}
                place={'bottom'}
                content={getTooltipText()}
            >
            </Tooltip>;
        }
    }, [buttonTooltipId, sanitized, t]);

    if (!ready) {
        return null;
    }

    return <>
        <Button
            variant={'link'}
            className={downloadSanitizedClassName}
            onClick={downloadSanitized}
            data-tip
            data-tooltip-id={buttonTooltipId}
        >
            <i className='icon icon-down' />
            {t('Sanitized version')}
        </Button>
        {tooltipDOM}
    </>;
};

DownloadSanitizedButton.propTypes = {
    sanitized: PropTypes.object,
    downloadSanitized: PropTypes.func.isRequired
};

export default DownloadSanitizedButton;

