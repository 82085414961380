/* eslint-disable security/detect-non-literal-regexp */
/* eslint-disable security/detect-unsafe-regex */
import { ERRORS } from '@mdc/constants';
import DomainList from './TLDS';

/* eslint no-useless-escape: 0 */
class ScanInputChecker {
    getTLD(domain) {
        const tempDomainArr = domain.split('.');
        return tempDomainArr[tempDomainArr.length - 1].toUpperCase();
    }

    cleanURL(URL) {
        let cleanURL = URL.replace(/([^:]\/)\/+/g, '$1');
        if (cleanURL.endsWith('/')) {
            return cleanURL.substring(0, cleanURL.length - 1);
        }
        return cleanURL;
    }

    isCVE(term) {
        let regex = /^(CVE-)?[1-2][\d]{3}-[\d]{4,8}$/;
        return !!term.toUpperCase().match(regex);
    }

    isDomain(term) {
        let regex = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/;
        const isDomainStructureValid = !!term.match(regex);
        const tld = this.getTLD(term);
        return isDomainStructureValid && DomainList.includes(tld);
    }

    isHash(term) {
        let test = term.toLowerCase(),
            md5 = /^[a-f0-9]{32}$/,
            sha1 = /\b([a-f0-9]{40})\b/,
            sha256 = /[a-f0-9]{64}/;
        return !!(test.match(md5) || test.match(sha1) || test.match(sha256));
    }

    isUrl(term) {
        try {
            let protocol = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)',
                domain = '[a-z0-9]+([\-\.]{1}[a-z0-9]+)*',
                webpage = '\.[a-z]{2,}(:[\\d]{1,5})?(\/.*)?$',
                regex = new RegExp(`${protocol}?${domain}\\${webpage}`);
            const urlObj = new URL(term);
            const tld = this.getTLD(urlObj.host);
            return DomainList.includes(tld) && !!term.toLowerCase().match(regex);
        } catch (e) {
            return false;
        }
    }

    /**
     * throws private ip error
     *
     * @param term
     * @returns {boolean}
     */
    isIP(term) {
        let domainName = term.replace(/https?:\/\//, ''),
            specialChar = /[\^\*\(\)\"\'\[\]\{\}\\<\>\,]/,
            allowIPV6 = /^((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(^25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|((((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))$/,
            networkPart = '^((25[0-5]|2[0-4][\\d]|[01]?[\\d][\\d]?)\\.){3}',
            hostPart = '(25[0-5]|2[0-4][\\d]|[01]?[\\d][\\d]?)$',
            allowIPV4 = new RegExp(`${networkPart}${hostPart}`),
            detectPrivateIPV4 = /^((127((\.(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?))){3})|(10((\.(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?)){3}))|(172\.1[6-9]((\.(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?))){2})|(172\.2[\d]((\.(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?))){2})|(172\.3[0-1]((\.(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?))){2})|(192\.168((\.(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?))){2})|(::1)|(0((\.(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?)){3}))|(255((\.(25[0-5]|2[0-4][\d]|[01]?[\d][\d]?)){3})))$/;

        if (domainName === '' ||
            domainName.match(specialChar) ||
            !(domainName.match(allowIPV6) || domainName.match(allowIPV4))) {
            return false;
        }

        if (domainName.match(detectPrivateIPV4)) {
            throw ERRORS.INVALID_IP;
        }

        return true;
    }
}

export default new ScanInputChecker();
